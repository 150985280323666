// import React from 'react'
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Home from "./pages/home";
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import Activities from "./pages/activities"
// import NavBar from './components/NavBar';
// import Footer from './components/Footer';
// import BehindTheScene from "./pages/behindthescene"
// import Vacations from "./pages/vacations"
// import Squad from "./pages/squad"
// import Qr from "./pages/qr"
// import Earnings from "./pages/earnings"
// import Settings from "./pages/settings"
// import GiftCode from "./pages/giftcode"
// import Mypackage from "./pages/package"
// export default function App() {
//   return (
//     <BrowserRouter>

//     <NavBar/>
//       <Routes>
//         <Route path="/" >
//           <Route index element={<Home />} />
//           <Route path="home" element={<Home />} />
//           <Route path="activities" element={<Activities />} />
//           <Route path="behind-the-scene" element={<BehindTheScene />} />
//           <Route path="vacations" element={<Vacations />} />
//           <Route path="squad" element={<Squad />} />
//           <Route path="qr" element={<Qr />} />
//           <Route path="earnings" element={<Earnings />} />
//           <Route path="settings" element={<Settings />} />
//           <Route path="giftcode" element={<GiftCode />} />
//           <Route path="mypackage" element={<Mypackage />} />

          
//           {/* <Route path="*" element={<NoPage />} /> */}
//         </Route>
//       </Routes>
//       <Footer/>
//     </BrowserRouter>
//   )
// }


// import axios from 'axios';
// import React, { useState } from 'react';
// import bcrypt from 'bcryptjs';

// export default function App() {
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const handleClick = async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       console.log("Fetching data from the API...");
      
      

    

//       await axios.post('http://localhost:3001/save-data', jsonListData,{
//         maxContentLength: 50 * 1024 * 1024, // 50MB
//         maxBodyLength: 50 * 1024 * 1024 // 50MB
//       })
//       .then(response => {
//         console.log('File has been saved');
//       })
//       .catch(error => {
//         console.error('Error fetching data', error);
//       });
//       console.log("JSON data is sent to the server for saving.");
//     } catch (err) {
//       console.error("Error fetching data", err);
//       setError("Failed to fetch data.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div onClick={handleClick}>
//       {loading ? "Loading..." : "App"}
//       {error && <div style={{ color: 'red' }}>{error}</div>}
//     </div>
//   );
// }




import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function App() {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    axios.get("https://api.dremerz.net/api/infinitifutureslogindata/")
      .then(response => {
        setUserData(response.data);
      })
      .catch(error => {
        console.error('Error fetching data', error);
      });
  }, []);

  return (
    <div>
      <h1>User Data</h1>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Sponsor Username</th>
            <th>Full Name</th>
            <th>Email</th>
            <th>Password</th>

            <th>Username</th>
            <th>Phone Number</th>
          
           
          </tr>
        </thead>
        <tbody>
          {userData.map((user, index) => (
            <tr key={index}>
              <td>{user.id}</td>
              <td>{user.sponsorUsername.toLowerCase()}</td>
              <td>{user.fullName}</td>

              <td>{user.email}</td>
              <td>{user.password}</td>
           
        
              <td>{user.username.toLowerCase()}</td>
             
              <td>{user.phoneNumber}</td>
            
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}


// import axios from 'axios'
// import React from 'react'

// export default function App() {
//   return (
//     <div
    
//     onClick={()=>{
//      axios.get("https://api.dremerz.net/api/infinitifutureslogindata/")
//       .then(response => {
//         response.data.map((data,index)=>{
//           axios.put("https://api.dremerz.net/api/infinitifutureslogindata/"+data.id.toString(),{

//           ref_id: (index+1).toString(),


//         })
//         })
//       })
//     }}
    
//     >App</div>
//   )
// }
